jQuery(document).ready(function($){
    //set your google maps parameters
    var latitude = 49.8097819,
        longitude = 19.0233881,
        map_zoom = 16;

    //google map custom marker icon - .png fallback for IE11
    var is_internetExplorer11= navigator.userAgent.toLowerCase().indexOf('trident') > -1;
    var marker_url = ( is_internetExplorer11 ) ? imgBaseUrl + '/dist/images/marker.png' : imgBaseUrl + '/dist/images/marker.png';

    //define the basic color of your map, plus a value for saturation and brightness
    var	main_color = '',
        saturation_value= 0,
        brightness_value= 0;

    //we define here the style of the map
    var style= [
        {
            //set saturation for the labels on the map
            elementType: "labels",
            stylers: [
                {saturation: saturation_value}
            ]
        },
        {	//poi stands for point of interest - don't show these lables on the map
            featureType: "poi",
            elementType: "labels",
            stylers: [
                {visibility: "off"}
            ]
        },
        {
            //don't show highways lables on the map
            featureType: 'road.highway',
            elementType: 'labels',
            stylers: [
                {visibility: "off"}
            ]
        },
        {
            //don't show local road lables on the map
            featureType: "road.local",
            elementType: "labels.icon",
            stylers: [
                {visibility: "off"}
            ]
        },
        {
            //don't show arterial road lables on the map
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
                {visibility: "off"}
            ]
        },
        {
            //don't show road lables on the map
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [
                {visibility: "off"}
            ]
        },
        //style different elements on the map
        {
            featureType: "transit",
            elementType: "geometry.fill",
            stylers: [
                { hue: main_color },
                { visibility: "on" },
                { lightness: brightness_value },
                { saturation: saturation_value }
            ]
        },
        {
            featureType: "poi",
            elementType: "geometry.fill",
            stylers: [
                { hue: main_color },
                { visibility: "on" },
                { lightness: brightness_value },
                { saturation: saturation_value }
            ]
        },
        {
            featureType: "poi.government",
            elementType: "geometry.fill",
            stylers: [
                { hue: main_color },
                { visibility: "on" },
                { lightness: brightness_value },
                { saturation: saturation_value }
            ]
        },
        {
            featureType: "poi.sport_complex",
            elementType: "geometry.fill",
            stylers: [
                { hue: main_color },
                { visibility: "on" },
                { lightness: brightness_value },
                { saturation: saturation_value }
            ]
        },
        {
            featureType: "poi.attraction",
            elementType: "geometry.fill",
            stylers: [
                { hue: main_color },
                { visibility: "on" },
                { lightness: brightness_value },
                { saturation: saturation_value }
            ]
        },
        {
            featureType: "poi.business",
            elementType: "geometry.fill",
            stylers: [
                { hue: main_color },
                { visibility: "on" },
                { lightness: brightness_value },
                { saturation: saturation_value }
            ]
        },
        {
            featureType: "transit",
            elementType: "geometry.fill",
            stylers: [
                { hue: main_color },
                { visibility: "on" },
                { lightness: brightness_value },
                { saturation: saturation_value }
            ]
        },
        {
            featureType: "transit.station",
            elementType: "geometry.fill",
            stylers: [
                { hue: main_color },
                { visibility: "on" },
                { lightness: brightness_value },
                { saturation: saturation_value }
            ]
        },
        {
            featureType: "landscape",
            stylers: [
                { hue: main_color },
                { visibility: "on" },
                { lightness: brightness_value },
                { saturation: saturation_value }
            ]

        },
        {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
                { hue: main_color },
                { visibility: "on" },
                { lightness: brightness_value },
                { saturation: saturation_value }
            ]
        },
        {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
                { hue: main_color },
                { visibility: "on" },
                { lightness: brightness_value },
                { saturation: saturation_value }
            ]
        },
        {
            featureType: "water",
            elementType: "geometry",
            stylers: [
                { hue: main_color },
                { visibility: "on" },
                { lightness: brightness_value },
                { saturation: saturation_value }
            ]
        }
    ];

    //set google map options
    var map_options = {
        center: new google.maps.LatLng(latitude, longitude),
        zoom: map_zoom,
        panControl: false,
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false,
        styles: style,
    }
    //inizialize the map
    var map = new google.maps.Map(document.getElementById('google-container'), map_options);
    //add a custom marker to the map
    /*var iconBase = imgBaseUrl;*/
    var marker = new google.maps.Marker({
        position: new google.maps.LatLng(latitude, longitude),
        map: map,
        visible: true,
        icon: marker_url,
        url: 'https://www.google.pl/maps/place/Eagle-Express+S.C.+Damian+Bi%C5%82ka+i+Micha%C5%82+Dro%C5%BAdzik/@49.809604,19.0225112,17z/data=!3m1!4b1!4m2!3m1!1s0x4716a032f87f92cd:0xe111948d4ddec7f2'
    });
    google.maps.event.addListener(marker, 'click', function() {
        window.location.href = this.url;
    });

    //add custom buttons for the zoom-in/zoom-out on the map
    function CustomZoomControl(controlDiv, map) {
        //grap the zoom elements from the DOM and insert them in the map
        var controlUIzoomIn= document.getElementById('cd-zoom-in'),
            controlUIzoomOut= document.getElementById('cd-zoom-out');
        controlDiv.appendChild(controlUIzoomIn);
        controlDiv.appendChild(controlUIzoomOut);

        // Setup the click event listeners and zoom-in or out according to the clicked element
        google.maps.event.addDomListener(controlUIzoomIn, 'click', function() {
            map.setZoom(map.getZoom()+1)
        });
        google.maps.event.addDomListener(controlUIzoomOut, 'click', function() {
            map.setZoom(map.getZoom()-1)
        });
    }

    var zoomControlDiv = document.createElement('div');
    var zoomControl = new CustomZoomControl(zoomControlDiv, map);

    //insert the zoom div on the top left of the map
    map.controls[google.maps.ControlPosition.RIGHT_TOP].push(zoomControlDiv);
});











/*//insert the zoom div on the top left of the map
map.controls[google.maps.ControlPosition.LEFT_TOP].push(zoomControlDiv);

var $slider = $(".slider"), $bullets = $(".bullets");
function calculateHeight(){
    var height = $(".slide.active").outerHeight();
    $slider.height(height);
}

$(window).resize(function() {
    calculateHeight();
    clearTimeout($.data(this, 'resizeTimer'));
});

function resetSlides(){
    $(".slide.inactive").removeClass("inactiveRight").removeClass("inactiveLeft");
}

function gotoSlide($activeSlide, $slide, className){
    $activeSlide.removeClass("active").addClass("inactive "+className);
    $slide.removeClass("inactive").addClass("active");
    calculateHeight();
    resetBullets();
    setTimeout(resetSlides, 300);
}

$(".next").on("click", function(){
    var $activeSlide = $(".slide.active"),
        $nextSlide = $activeSlide.next(".slide").length !== 0 ? $activeSlide.next(".slide") : $(".slide:first-child");
    console.log($nextSlide);
    gotoSlide($activeSlide, $nextSlide, "inactiveLeft");
});
$(".previous").on("click",  function(){
    var $activeSlide = $(".slide.active"),
        $prevSlide = $activeSlide.prev(".slide").length !== 0 ? $activeSlide.prev(".slide") : $(".slide:last-child");

    gotoSlide($activeSlide, $prevSlide, "inactiveRight");
});
$(document).on("click", ".bullet", function(){
    if($(this).hasClass("active")){
        return;
    }
    var $activeSlide = $(".slide.active");
    var currentIndex = $activeSlide.index();
    var targetIndex = $(this).index();
    console.log(currentIndex, targetIndex);
    var $theSlide = $(".slide:nth-child("+(targetIndex+1)+")");
    gotoSlide($activeSlide, $theSlide, currentIndex > targetIndex ? "inactiveRight" : "inactiveLeft");
}),
    function addBullets(){
        var total = $(".slide").length, index = $(".slide.active").index();
        for (var i=0; i < total; i++){
            var $bullet = $("<div>").addClass("bullet");
            if(i===index){
                $bullet.addClass("active");
            }
            $bullets.append($bullet);
        }
    },
    function resetBullets(){
        $(".bullet.active").removeClass("active");
        var index = $(".slide.active").index()+1;
        console.log(index);
        $(".bullet:nth-child("+index+")").addClass("active");
    },
    addBullets();
calculateHeight();*/

